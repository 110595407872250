<template>
  <div class="dashboard-annual">
    <div class="filter-section">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-select v-model="selectedUser" placeholder="请选择客户跟进人" clearable>
            <el-option label="全部" value=""></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select v-model="sortBy" placeholder="最近更新时间降序">
            <el-option label="最近更新时间降序" value="latest"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select v-model="projectType" placeholder="负责项目" clearable>
            <el-option label="全部" value=""></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
      </el-row>
    </div>

    <div class="action-section">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="12">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="执行项目" name="executing"></el-tab-pane>
            <el-tab-pane label="提案项目" name="proposal"></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="primary" @click="handleImportProject">导入项目</el-button>
          <el-button type="primary" @click="handleImportOrder">导入订单</el-button>
          <el-button type="primary" @click="handleExport">导出</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="statistics-section">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card shadow="never">
            <div class="stat-item">
              <div class="stat-label">执行金额(元)</div>
              <div class="stat-value">{{ executionAmount }}</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never">
            <div class="stat-item">
              <div class="stat-label">已收款(元)</div>
              <div class="stat-value">{{ receivedAmount }}</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never">
            <div class="stat-item">
              <div class="stat-label">框架金额(元)</div>
              <div class="stat-value">{{ frameworkAmount }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class="table-section">
      <el-table
        :data="tableData"
        style="width: 100%"
        border>
        <el-table-column prop="projectInfo" label="项目信息" width="180"></el-table-column>
        <el-table-column prop="frameworkAmount" label="框架金额" width="150"></el-table-column>
        <el-table-column prop="executionAmount" label="执行金额" width="150"></el-table-column>
        <el-table-column prop="projectOwner" label="项目负责人" width="120"></el-table-column>
        <el-table-column prop="newOrders" label="新增订单" width="120"></el-table-column>
        <el-table-column prop="completedOrders" label="完成订单" width="120"></el-table-column>
        <el-table-column prop="invoiced" label="已开票" width="120"></el-table-column>
        <el-table-column prop="received" label="已收款" width="120"></el-table-column>
        <el-table-column prop="projectStage" label="项目阶段" width="120"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardAnnual',
  data() {
    return {
      selectedUser: '',
      sortBy: 'latest',
      projectType: '',
      dateRange: [],
      activeTab: 'executing',
      executionAmount: '0.00',
      receivedAmount: '0.00',
      frameworkAmount: '0.00',
      tableData: []
    }
  },
  methods: {
    handleImportProject() {
      // 处理导入项目
    },
    handleImportOrder() {
      // 处理导入订单
    },
    handleExport() {
      // 处理导出
    },
    handleEdit(row) {
      // 处理编辑
      console.log('编辑行:', row)
    },
    handleDelete(row) {
      // 处理删除
      console.log('删除行:', row)
    }
  }
}
</script>

<style scoped>
.dashboard-annual {
  padding: 24px;
}

.filter-section {
  margin-bottom: 24px;
}

.action-section {
  margin-bottom: 24px;
}

.statistics-section {
  margin-bottom: 24px;
}

.stat-item {
  text-align: center;
  padding: 20px;
}

.stat-label {
  font-size: 14px;
  color: #909399;
  margin-bottom: 8px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #303133;
}

.table-section {
  background: #fff;
  padding: 24px;
  border-radius: 4px;
}

.el-select {
  width: 100%;
}

.el-date-picker {
  width: 100%;
}
</style> 